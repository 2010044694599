<template>
    <div class="pl-store__acticle-wraper">
        <div class="pl-store__acticle-body">

        <div class="pl-store__acticle-module-title">
            <h2>{{info.name}}</h2>
            <!-- <p>{{info.updated_at}}</p> -->
        </div>
        <div class="pl-store__acticle-content-box" v-html="info.content">
            
        </div>
        </div>
    </div>
</template>
<script>
import {acticleShow} from '@/api/acticle.js'
export default {
    data() {
        return {
            id: this.$route.params.id,
            info: {
            }
        }
    },
    created() {
        acticleShow(this.id).then((data) => {
            this.info = data
        })
    },
    watch:{
        $route({params}) {
            acticleShow(params.id).then((data) => {
            this.info = data
        })
        }
    }
}
</script>
<style lang="scss" scoped>
.pl-store__acticle-module-title{
    color: #1db3c9;
    padding: 0 0 12px 6px;
    // border-bottom: 2px solid #e7e7e7;
    margin: 0 40px;
    font-size: 12px;
    color: #9b9b9b;
    h2{
        font-size: 18px;
        padding-bottom: 15px;
        color: #000
    }
}
.pl-store__acticle-body {
    padding: 20px 0;
    background: #fff;
    // margin: 20px auto;
}
.pl-store__acticle-content-box {
    padding: 20px 30px;
    ::v-deep p {
        margin: 15px 0!important;
        line-height: 1.5;
        text-indent: 2em;
    }
    ::v-deep img {
        display: inline-block;
        width: 100%;
        border-radius: 15px;
        margin: auto
    }
}
</style>